.board-list {
  padding: 32px 0;
  max-height: none !important;

  .board-list-item {
    min-width: 210px;
    min-height: 210px;
    width: 210px;
    height: 210px;
    padding: 24px 0;
    margin: 16px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.12);
    transition: box-shadow 150ms ease-in;
    cursor: pointer;

    &:hover:not(.add-new-board) {
      //@at-root  @include mat-elevation(8);
    }

    .board-name {
      padding: 16px 32px 0 32px;
      //  font-weight: 300;
      font-size: 16px;
      text-align: center;
    }

    .board-desc {
      padding: 16px 32px 0 32px;
      font-weight: 200;
      font-size: 12px;
      text-align: center;
    }

    &.add-new-board {
      background: rgba(0, 0, 0, 0.06);
      border: 2px dashed rgba(0, 0, 0, 0.37);
    }
  }
}
