@use '~@angular/material/theming' as ngMat;
@use 'components' as components;
@use './mixins/colors' as colors;

@import './variables.scss';

// @import '~perfect-scrollbar/css/perfect-scrollbar';

@import './nk.scss';

// Include core Angular Material styles
@include ngMat.mat-core(
  ngMat.mat-typography-config(
    $font-family: 'Raleway, "Open Sans", Roboto, "Helvetica Neue", sans-serif'
  )
);

// @include colors.color-generator(colors.$MatColorsMap);

@include components.custom-components();

@mixin createTheme($theme, $background, $foreground) {
  @include ngMat.angular-material-theme($theme);
  @include components.custom-components-theme($theme);
  // @include colors.theme-color-generator($theme);
}

@each $theme-name, $theme-data in $nk-themes {
  $theme: map-get($theme-data, 'theme');
  $theme-klass: map-get($theme-data, 'klass');
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  // @if $theme-klass!= '' {
  //   $theme-klass: '.' + $theme-klass;
  // }
  @if ($theme-klass== '') {
    body {
      background-color: map-get($background, 'background');
      color: map-get($foreground, 'text');
    }
    @include createTheme($theme, $background, $foreground);
  } @else {
    .#{$theme-klass} {
      background-color: map-get($background, 'background');
      color: map-get($foreground, 'text');
      @include createTheme($theme, $background, $foreground);
    }
  }
}

// Partials
@import './partials/helpers';
@import './partials/navigation';
@import './partials/scroll-bars';
// @import './partials/type';
@import './partials/typography';
// @import 'plugins/break';
// @import 'plugins/form-panel';
// @import 'plugins/drop-zone-fix';
// @import 'plugins/dialog-fix';
@import './new/border';
@import 'new/layout';
@import './new/board';
// @import 'new/table';
// @import 'new/stepper';
