@use '~@angular/material/theming' as ngMat;

/* $nk-default-primary: ngMat.mat-palette(ngMat.$mat-indigo, 800, 300, 900);
$nk-default-accent: ngMat.mat-palette(ngMat.$mat-light-blue);
$nk-default-warn: ngMat.mat-palette(ngMat.$mat-pink, 600);

$nk-default-theme: ngMat.mat-light-theme(
  $nk-default-primary,
  $nk-default-accent,
  $nk-default-warn
); */
$nk-default-primary: ngMat.mat-palette(ngMat.$mat-blue-grey);
$nk-default-accent: ngMat.mat-palette(ngMat.$mat-orange, A200, A100, A400);
$nk-default-warn: ngMat.mat-palette(ngMat.$mat-red);

$nk-default-theme: ngMat.mat-light-theme(
  $nk-default-primary,
  $nk-default-accent,
  $nk-default-warn
);
