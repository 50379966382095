@use '~@angular/material/theming' as ngMat;

@mixin default() {
  .panel-box {
    display: flex;
    flex: 1 1 auto; // flex-direction: column;

    .panel-group {
      // display: flex;
      // flex: 1 0 auto;
      // flex-direction: column;
      padding: 10px;
      position: relative;
      border: 1px solid;
      border-radius: 5px;
      padding: 28px 16px 8px 16px;
      margin: 16px 0;
      width: 100%;
      padding-bottom: 30px;

      .panel-name {
        position: absolute;
        top: -11px;
        left: 8px;
        margin: 0;
        padding: 0 8px;
        font-size: 16px;
        font-weight: 500;
      }

      .panel-sub-name {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.54);
        margin: 24px 0 16px 0;
        padding: 0;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}

@mixin form-panel-component($theme) {
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  .panel-box {
    .panel-group {
      border-color: map-get($foreground, 'disabled');

      .panel-name {
        background: map-get($background, 'background');
        color: ngMat.mat-color($accent);
      }

      // .panel-sub-name {
      //   color: rgba(0, 0, 0, 0.54);
      // }
    }
  }
}

// Back-ground
/* (
  status-bar: #e0e0e0,
  app-bar: #f5f5f5,
  background: #fafafa,
  hover: rgba(0, 0, 0, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(0, 0, 0, 0.12),
  raised-button: white,
  focused-button: rgba(0, 0, 0, 0.12),
  selected-button: #e0e0e0,
  selected-disabled-button: #bdbdbd,
  disabled-button-toggle: #eeeeee,
  unselected-chip: #e0e0e0,
  disabled-list-option: #eeeeee,
  tooltip: #616161,
); */
// For-ground
/* (
  base: black,
  divider: rgba(0, 0, 0, 0.12),
  dividers: rgba(0, 0, 0, 0.12),
  disabled: rgba(0, 0, 0, 0.38),
  disabled-button: rgba(0, 0, 0, 0.26),
  disabled-text: rgba(0, 0, 0, 0.38),
  elevation: black,
  hint-text: rgba(0, 0, 0, 0.38),
  secondary-text: rgba(0, 0, 0, 0.54),
  icon: rgba(0, 0, 0, 0.54),
  icons: rgba(0, 0, 0, 0.54),
  text: rgba(0, 0, 0, 0.87),
  slider-min: rgba(0, 0, 0, 0.87),
  slider-off: rgba(0, 0, 0, 0.26),
  slider-off-active: rgba(0, 0, 0, 0.38),
) */
