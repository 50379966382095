// .hf {
//   background-color: map-get(mat-palette($mat-grey), 200);
//   // background-color: #F9F9F9;
// }

.oa {
  overflow: auto;
}

.os {
  overflow: scroll;
}
