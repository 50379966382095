@use '~@angular/material/theming' as ngMat;

/* $nk-black-primary: ngMat.mat-palette(ngMat.$mat-grey, 700, 300, 900);
$nk-black-accent: ngMat.mat-palette(ngMat.$mat-blue-grey, 400);
$nk-black-warn: ngMat.mat-palette(ngMat.$mat-red, 500);

$nk-black-theme: ngMat.mat-dark-theme(
  $nk-black-primary,
  $nk-black-accent,
  $nk-black-warn
); */
$nk-black-primary: ngMat.mat-palette(ngMat.$mat-pink, 700, 300, 900);
$nk-black-accent: ngMat.mat-palette(ngMat.$mat-blue-grey, 400);
$nk-black-warn: ngMat.mat-palette(ngMat.$mat-red, 500);

$nk-black-theme: ngMat.mat-dark-theme(
  $nk-black-primary,
  $nk-black-accent,
  $nk-black-warn
);
