@use '~@angular/material/theming' as ngMat;

@mixin default() {
}

@mixin ngx-file-drop-component($theme) {
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  .ngx-file-drop {
    &__drop-zone {
      border: 2px dashed map-get($foreground, 'disabled') !important;
      border-radius: 5px !important;
    }
    &__content {
      color: map-get($foreground, 'disabled') !important;
    }
  }
}
