/* .secondary-text,
.icon,
i {
  color: rgba(0, 0, 0, 0.54);
}

.hint-text,
.disabled-text {
  color: rgba(0, 0, 0, 0.38);
}

.divider {
  color: rgba(0, 0, 0, 0.12);
} */

// Material colors map

@use '~@angular/material/theming' as ngMat;
@use '../material/palettes';

$MatColorsMap: (
  'red': ngMat.$mat-red,
  'pink': ngMat.$mat-pink,
  'purple': ngMat.$mat-purple,
  'deep-purple': ngMat.$mat-deep-purple,
  'indigo': ngMat.$mat-indigo,
  'blue': ngMat.$mat-blue,
  'light-blue': ngMat.$mat-light-blue,
  'cyan': ngMat.$mat-cyan,
  'teal': ngMat.$mat-teal,
  'green': ngMat.$mat-green,
  'light-green': ngMat.$mat-light-green,
  'lime': ngMat.$mat-lime,
  'yellow': ngMat.$mat-yellow,
  'amber': ngMat.$mat-amber,
  'orange': ngMat.$mat-orange,
  'deep-orange': ngMat.$mat-deep-orange,
  'brown': ngMat.$mat-brown,
  'grey': ngMat.$mat-grey,
  'blue-grey': ngMat.$mat-blue-grey,
  'white': palettes.$mat-white,
  'black': palettes.$mat-black,
  'nk-dark': palettes.$nk-dark,
);
// Material color hues list
$matColorHues: (
  50,
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  A100,
  A200,
  A400,
  A700
);

// Text color levels generator mixin
@mixin generateTextColorLevels($baseTextColor) {
  // If the base text color is black...
  @if (rgba(black, 1) ==rgba($baseTextColor, 1)) {
    i,
    .icon {
      color: rgba(0, 0, 0, 0.54);
    }

    &.secondary-text,
    .secondary-text {
      color: rgba(0, 0, 0, 0.54) !important;
    }

    &.hint-text,
    .hint-text,
    &.disabled-text,
    .disabled-text {
      color: rgba(0, 0, 0, 0.38) !important;
    }

    &.divider,
    .divider {
      color: rgba(0, 0, 0, 0.12) !important;
    }

    .mat-ripple-element {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  // If the base text color is white...
  @else {
    i,
    .icon {
      color: rgba(255, 255, 255, 1);
    }

    &.secondary-text,
    .secondary-text {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    &.hint-text,
    .hint-text,
    &.disabled-text,
    .disabled-text {
      color: rgba(255, 255, 255, 0.5) !important;
    }

    &.divider,
    .divider {
      color: rgba(255, 255, 255, 0.12) !important;
    }

    .mat-ripple-element {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

@mixin generateMaterialElementColors($contrastColor) {
  // If the contrast color is white...
  $nkForeground: (
    base: white,
    text: white,
    hint-text: rgba(white, 0.5),
    divider: rgba(white, 0.12),
  ); // If the contrast color is black...

  @if (rgba(black, 1) ==rgba($contrastColor, 1)) {
    $nkForeground: (
      base: black,
      hint-text: rgba(black, 0.38),
      divider: rgba(black, 0.12),
    );
  }

  // Native Input
  input[type='text'] {
    color: map_get($nkForeground, base);
  }

  // Input
  .mat-form-field-label {
    color: map_get($nkForeground, hint-text);
  }

  .mat-form-field-underline {
    background-color: map_get($nkForeground, divider);
  }

  // Select
  .mat-select-trigger,
  .mat-select-arrow {
    color: map_get($nkForeground, hint-text);
  }

  .mat-select-underline {
    background-color: map_get($nkForeground, divider);
  }

  .mat-select-disabled .mat-select-value,
  .mat-select-arrow,
  .mat-select-trigger {
    color: map_get($nkForeground, hint-text);
  }

  /*  TODO:fix it
.mat-select-content,
  .mat-select-panel-done-animating {
    background: map_get($background, card);
  }
  */

  .mat-select-value {
    color: map_get($nkForeground, text);
  }
}

// Color classes generator mixin
@mixin generateColorClasses($colorName, $color, $contrastColor, $hue) {
  /*   .#{$colorName}#{$hue}-bg {
    background-color: $color !important;
  } */

  .mat-#{$colorName}#{$hue}-bg {
    background-color: $color !important;
    color: $contrastColor !important;

    // Generate text color levels
    // based on current contrast color
    //--- @include generateTextColorLevels($contrastColor);

    // Generate material element colors
    // based on current contrast color
    //--- @include generateMaterialElementColors($contrastColor);

    // &[disabled] {
    //   background-color: rgba($color, 0.12) !important;
    //   color: rgba($contrastColor, 0.26) !important;
    // }
  }

  .#{$colorName}#{$hue}-fg {
    color: $color !important;

    // Generate text color levels
    // based on current contrast color
    // @include generateTextColorLevels($color);

    // Generate material element colors
    // based on current contrast color
    // @include generateMaterialElementColors($color);
  }

  /*   .#{$colorName}#{$hue}-border {
    border-color: $color !important;
  }

  .#{$colorName}#{$hue}-border-top {
    border-top-color: $color !important;
  }

  .#{$colorName}#{$hue}-border-right {
    border-right-color: $color !important;
  }

  .#{$colorName}#{$hue}-border-bottom {
    border-bottom-color: $color !important;
  }

  .#{$colorName}#{$hue}-border-left {
    border-left-color: $color !important;
  } */
}

// Generate the color classes...
@mixin color-generator($colorsMap) {
  @each $colorName, $colorMap in $colorsMap {
    @each $hue in $matColorHues {
      $color: map-get($colorMap, $hue);
      $contrastColor: map-get(map-get($colorMap, 'contrast'), $hue);

      @if ($color !=null and $contrastColor !=null) {
        @include generateColorClasses(
          $colorName,
          $color,
          $contrastColor,
          '-#{$hue}'
        ); // Run the generator one more time for default values (500)

        @if ($hue==500) {
          @include generateColorClasses($colorName, $color, $contrastColor, '');
        }
      }
    }
  }
}

@mixin theme-color-generator($theme) {
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');
  $themeColorsMap: (
    'primary': $primary,
    'accent': $accent,
    'warn': $warn,
  );
  @include color-generator($themeColorsMap);
}
