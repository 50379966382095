@use '~@angular/material/theming' as ngMat;

@mixin nk-chat-component($theme) {
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  nk-chat {
    .chat-sidebar {
      .header {
        background: darken(map-get($background, 'background'), 5%);
      }
    }
    .content {
      .content-header {
        background: darken(map-get($background, 'background'), 5%);
      }
      .chat-area {
        .bubble {
          color: ngMat.mat-color($accent, default-contrast);
          background: ngMat.mat-color($accent, 400);
        }
        .me .bubble {
          color: map-get($foreground, 'text');
          background: darken(map-get($background, 'background'), 5%);
        }
      }
      .msg-input {
        background: darken(map-get($background, 'background'), 5%);
        .msg-input-box {
          background: map-get($background, 'background');
        }
      }
    }
  }
}
