@use '~@angular/material/theming' as ngMat;

/* $nk-nature-primary: ngMat.mat-palette(ngMat.$mat-brown, 700, 300, 900);
$nk-nature-accent: ngMat.mat-palette(ngMat.$mat-deep-purple, 300);
$nk-nature-warn: ngMat.mat-palette(ngMat.$mat-red, 600);

$nk-nature-theme: ngMat.mat-light-theme(
  $nk-nature-primary,
  $nk-nature-accent,
  $nk-nature-warn
); */

$nk-dark-primary: ngMat.mat-palette(ngMat.$mat-purple);
$nk-dark-accent: ngMat.mat-palette(ngMat.$mat-green, A200, A100, A400);
$nk-dark-warn: ngMat.mat-palette(ngMat.$mat-red);

$nk-dark-theme: ngMat.mat-dark-theme(
  $nk-dark-primary,
  $nk-dark-accent,
  $nk-dark-warn
);
