@use '~@angular/material/theming' as ngMat;

@mixin nav-component($theme) {
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  .nav {
    .nav-item {
      .nav-link {
        // color: ngMat.mat-color($accent, default-contrast);

        &:hover {
          background-color: map-get($background, 'hover');
        }

        .mat-ripple-element {
          background-color: map-get($background, 'hover');
        }

        &.active {
          background-color: ngMat.mat-color($accent);

          .mat-ripple-element {
            background-color: ngMat.mat-color($accent, default-contrast, 0.1);
          }

          &,
          .nav-link-icon {
            color: ngMat.mat-color($accent, default-contrast);
          }

          .nav-link-badge {
            background: #ffffff !important;
            color: rgba(0, 0, 0, 0.87) !important;
          }
        }
      }

      > .nav-item {
        &.nav-collapse {
          background: transparent;

          &.open {
            background: rgba(0, 0, 0, 0.12);
          }
        }
      }

      > .nav-group {
        > .group-items {
          > .nav-collapse {
            background: transparent;

            &.open {
              background: rgba(0, 0, 0, 0.12);
            }
          }
        }
      }
    }
  }
}
