@use '~@angular/material/theming' as ngMat;

/* $nk-light-primary: ngMat.mat-palette(ngMat.$mat-grey, 200, 500, 300);
$nk-light-accent: ngMat.mat-palette(ngMat.$mat-brown, 200);
$nk-light-warn: ngMat.mat-palette(ngMat.$mat-deep-orange, 200);

$nk-light-theme: ngMat.mat-light-theme(
  $nk-light-primary,
  $nk-light-accent,
  $nk-light-warn
); */
$nk-light-primary: ngMat.mat-palette(ngMat.$mat-deep-purple);
$nk-light-accent: ngMat.mat-palette(ngMat.$mat-amber, A200, A100, A400);
$nk-light-warn: ngMat.mat-palette(ngMat.$mat-red);

$nk-light-theme: ngMat.mat-light-theme(
  $nk-light-primary,
  $nk-light-accent,
  $nk-light-warn
);
