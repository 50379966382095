@use '~@angular/material/theming' as ngMat;

@mixin nk-create-post-component($theme) {
  $primary: map-get($theme, 'primary');
  $accent: map-get($theme, 'accent');
  $warn: map-get($theme, 'warn');
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  nk-create-post {
    background: linear-gradient(
      0deg,
      ngMat.mat-color($primary, 50, 0.2) 80%,
      ngMat.mat-color($primary, 400) 80%
    );

    .post-form {
      background: map-get($background, 'background');
    }
    .fancy-border {
      color: ngMat.mat-color($accent, 400);
    }
  }
}
