// ######################
// POSITION HELPERS
// ######################
// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
$infix: '';
.position#{$infix}-relative {
  position: relative;
}

.position#{$infix}-absolute {
  position: absolute;
}

.position#{$infix}-static {
  position: static;
}
//   }
// }

// ####################################
// ABSOLUTE POSITION ALIGNMENT HELPERS
// ####################################
// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
$infix: '';

.align#{$infix}-top {
  top: 0;
}

.align#{$infix}-right {
  right: 0;
}

.align#{$infix}-bottom {
  bottom: 0;
}

.align#{$infix}-left {
  left: 0;
}
//   }
// }

// ######################
// BORDER HELPERS
// ######################
$border-style: 1px solid rgba(0, 0, 0, 0.12);

.b {
  border: $border-style;
}

.bt {
  border-top: $border-style;
}

.br {
  border-right: $border-style;
}

.bb {
  border-bottom: $border-style;
}

.bl {
  border-left: $border-style;
}

.bx {
  border-left: $border-style;
  border-right: $border-style;
}

.by {
  border-top: $border-style;
  border-bottom: $border-style;
}

// ######################
// BORDER RADIUS HELPERS
// ######################
.br25p {
  border-radius: 25%;
}
.br50p {
  border-radius: 50%;
}
.br75p {
  border-radius: 75%;
}
.br100p {
  border-radius: 100%;
}
.br4 {
  border-radius: 4px;
}

.br8 {
  border-radius: 8px;
}

.br16 {
  border-radius: 16px;
}

// ######################
// CURSOR HELPERS
// ######################
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.df {
  display: flex;
}

.dif {
  display: inline-flex;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.fl {
  float: left;
}

.fr {
  float: right;
}
